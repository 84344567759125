export const audioManager = {
  playNewSound: ({
    audioCtx,
    buffer,
    offset,
    gainNode,
    existingSrc,
    connect,
  }: {
    connect: boolean
    audioCtx: AudioContext
    buffer: AudioBuffer
    offset: number
    gainNode?: GainNode
    existingSrc?: AudioBufferSourceNode | null
  }) => {
    if (existingSrc) {
      existingSrc.stop()
      existingSrc.disconnect()
    }
    const src = audioCtx.createBufferSource()
    src.buffer = buffer
    if (connect) {
      src.connect(audioCtx.destination)
    }
    if (gainNode) {
      src.connect(gainNode)
    }
    src.start(0, offset)
    return src
  },
}
