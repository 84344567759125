import type { FunctionComponent, PropsWithChildren } from 'react'
import { createContext, useContext, useEffect, useRef } from 'react'

import { GA_EVENTS, PLAYER_REFS } from '../../constants'

import { useRefContext } from 'context/RefContext'
import { gaSendCustomEvent } from 'helpers/analytics'
import { useAudioPlayer } from 'hooks/useAudioPlayer'
import { useReferredState } from 'hooks/useReferredState'
import type { EventAudioBuffer } from 'types/player'
import { PlayerState } from 'types/player'

export type SyncPlayerContextData = {
  state: PlayerState
  play(): boolean
  stop(): void
  toggleMuted(): void
  muted: boolean
  allBuffersLoaded: boolean
}

export const SyncPlayerContext = createContext<
  SyncPlayerContextData | undefined
>(undefined)

export function useSyncPlayerContext() {
  const data = useContext(SyncPlayerContext)
  if (!data) {
    throw new Error(
      'The component needs to be wrapped with SyncPlayerContextProvider',
    )
  }
  return data
}

export const SyncPlayerContextProvider: FunctionComponent<
  PropsWithChildren
> = ({ children }) => {
  const audioCtx = useRef<AudioContext>(new AudioContext())
  const audioRef = useRef<HTMLAudioElement>(new Audio())
  const audioBuffer = useRef<AudioBuffer | null>(null)
  const bufferSrcArray = useRef<Array<EventAudioBuffer>>([])

  const { setRef, getRef } = useRefContext()

  /**
   * We store our audio context, element and
   * associated objects in the global ref store (RefContext),
   * so they don't get lost in the render loop.
   *
   * This is necessary because we need to access these objects
   * in the useAudioPlayer hook. It could be refactored to somewhere
   * more robust in the future, it's set here mainly
   * for the convenience of the analytics events (see below ga pushes)
   */
  useEffect(() => {
    if (
      getRef(PLAYER_REFS.AUDIO_CONTEXT) &&
      getRef(PLAYER_REFS.AUDIO_ELEMENT) &&
      getRef(PLAYER_REFS.AUDIO_BUFFER) &&
      getRef(PLAYER_REFS.AUDIO_BUFFER_SRC_ARRAY)
    )
      return
    setRef(PLAYER_REFS.AUDIO_CONTEXT, audioCtx)
    setRef(PLAYER_REFS.AUDIO_ELEMENT, audioRef)
    setRef(PLAYER_REFS.AUDIO_BUFFER, audioBuffer)
    setRef(PLAYER_REFS.AUDIO_BUFFER_SRC_ARRAY, bufferSrcArray)
  })

  const { state, play, toggleMuted, muted, allBuffersLoaded } =
    useAudioPlayer()

  const [stopAndClose, setStopAndClose] = useReferredState<boolean>(false)

  const sendDuration = () => {
    if (!stopAndClose.current) {
      // console.log(`Duration ${audioCtx.current.currentTime}`)
      gaSendCustomEvent(GA_EVENTS.DURATION, {
        value: audioCtx.current.currentTime,
      })
    }
  }

  useEffect(() => {
    if (state === PlayerState.PLAYING) {
      window.addEventListener('beforeunload', sendDuration)
    }
    return () => {
      window.removeEventListener('beforeunload', sendDuration)
    }
  }, [state])

  useEffect(() => {
    if (stopAndClose.current) {
      // console.log(`Duration from stop ${audioCtx.current.currentTime}`)
      gaSendCustomEvent(GA_EVENTS.DURATION, {
        value: audioCtx.current?.currentTime,
      })
      // Reload user to home page
      window.location.reload()
    }
  }, [stopAndClose.current])

  const stop = () => {
    gaSendCustomEvent(GA_EVENTS.STOP)
    setStopAndClose(true)
  }

  return (
    <SyncPlayerContext.Provider
      value={{
        play,
        stop,
        state,
        toggleMuted,
        muted,
        allBuffersLoaded,
      }}
    >
      {children}
    </SyncPlayerContext.Provider>
  )
}
