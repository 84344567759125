import type { MutableRefObject } from 'react'
import { useEffect, useRef } from 'react'

import { PLAYER_REFS } from '../constants'

import { useEffectOnce } from './useEffectOnce'

import { useSyncPlayerContext } from 'components/SyncPlayer/SyncPlayerContext'
import { useRefContext } from 'context/RefContext'
import { getConfigData } from 'helpers/config'
import { PlayerState } from 'types/player'

export function useAnalyzer() {
  const { state } = useSyncPlayerContext()

  const { getRef } = useRefContext()

  const audioCtx = () =>
    getRef(PLAYER_REFS.AUDIO_CONTEXT) as MutableRefObject<AudioContext>

  const bufferSrc = () =>
    getRef(
      PLAYER_REFS.AUDIO_BUFFER_SRC,
    ) as MutableRefObject<AudioBufferSourceNode | null>

  const analyzer = useRef<AnalyserNode>()
  const audioConfig = getConfigData('audioConfig')

  useEffectOnce(() => {
    // Analyser setup for Vizusaliser
    if (audioConfig.showVisualizer) {
      analyzer.current = audioCtx().current.createAnalyser()
    }
  })

  useEffect(() => {
    if (
      state !== PlayerState.PLAYING ||
      !audioCtx().current ||
      !audioConfig.showVisualizer
    ) {
      return
    }

    analyzer.current = audioCtx().current.createAnalyser()
    bufferSrc().current?.connect(analyzer.current)
    analyzer.current.fftSize = audioConfig.fftSize
  }, [state])

  return analyzer.current
}
