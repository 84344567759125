import { useEffect, useMemo, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import {
  ANDROID_DEGRADATION_VERSION,
  IOS_DEGRADATION_VERSION,
  GA_EVENTS,
} from '../constants'

import { Button } from 'components/Button'
import { HTML_CONTENT_FORMATTING } from 'components/HtmlContent'
import { Screen } from 'components/Screen'
import { Sponsors } from 'components/Sponsors'
import { Headline, Text } from 'components/typography'
import { useSyncServerContext } from 'context/SyncServerContext'
import { getAndroidVersion, getIOSVersion } from 'helpers/ua'
import { useConfigProperties } from 'hooks/useEsConfig'
import { useHtmlContent } from 'hooks/useHtmlContent'
import { useLocaleTranslation } from 'hooks/useLocaleTranslation'
import { AppScreen } from 'layouts/styles'
import { pxToRem, space } from 'theme/utils'
import { PageEventType, usePageEventTracking } from 'utils/rudderstack'

const useDisclaimerStates = () => {
  const [iOS, setIOS] = useState<boolean>(false)
  const [android, setAndroid] = useState<boolean>(false)

  useEffect(() => {
    const getVersions = async () => {
      const androidVersion = await getAndroidVersion()
      const iOSVersion = getIOSVersion()
      setIOS(!!iOSVersion && iOSVersion <= IOS_DEGRADATION_VERSION)
      setAndroid(
        !!androidVersion && androidVersion <= ANDROID_DEGRADATION_VERSION,
      )
    }
    getVersions()
  }, [])

  return {
    android,
    iOS,
  }
}

const EventUI = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${space(4)};
  padding-bottom: ${space(4)};
  align-items: center;
  width: ${pxToRem(210)};
  margin: 0 auto;
  button {
    width: ${pxToRem(210)};
  }
  button + p {
    margin-top: -${space(2)};
  }
`

export function IntroView(): JSX.Element | null {
  const { _, t } = useLocaleTranslation('Home')

  const { isServerReady, offlineMessages: message } = useSyncServerContext()
  const navigate = useNavigate()

  usePageEventTracking({
    pageName: PageEventType.LANDING,
  })

  const { android, iOS } = useDisclaimerStates()

  const { name, description } = useConfigProperties()

  const summary: string[] = useMemo(() => {
    if (!message || message.length === 0) {
      return t('Home.summary', {
        returnObjects: true,
      })
    }

    return message
  }, [message])

  const startClickHandler = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    navigate('/player/active', { replace: true })
  }

  const getHtmlContent = useHtmlContent({
    allowedHtmlTags: HTML_CONTENT_FORMATTING,
  })

  return (
    <AppScreen>
      <Screen gaEvent={GA_EVENTS.HOME}>
        <Headline $size="large">{name}</Headline>
        {description && getHtmlContent(description)}
        <EventUI>
          {summary.map((p, i) => (
            <Text
              $size="medium"
              key={i}
              dangerouslySetInnerHTML={{ __html: p }}
            />
          ))}
          <Button
            onClick={startClickHandler}
            disabled={!isServerReady}
            appearance={isServerReady ? 'solid' : 'outline'}
          >
            {isServerReady
              ? _('start-button-active')
              : _('start-button-disabled')}
          </Button>

          {android && <Text $size="xSmall">{_('disclaimer-android')}</Text>}
          {iOS && <Text $size="xSmall">{_('disclaimer-ios')}</Text>}
        </EventUI>

        <Sponsors />
      </Screen>
    </AppScreen>
  )
}
