import type { MutableRefObject } from 'react'
import { useState } from 'react'

import {
  ANDROID_DEGRADATION_VERSION,
  BLANK_TRACK,
  PLAYER_REFS,
} from '../constants'

import { useAppContext } from 'context/AppContext'
import { useRefContext } from 'context/RefContext'
import { getAudioData } from 'helpers/player'
import { getAndroidVersion } from 'helpers/ua'
import type { EventAudioBuffer, EventTrack, PlayerState } from 'types/player'

export function useAudioBuffer() {
  const { tracks } = useAppContext()

  const [longAudioTime, setLongAudioTime] = useState<number>(0)
  const { getRef, setRefValue } = useRefContext()
  const [bufferCounter, setBufferCounter] = useState<number>(0)

  const audioCtx = () =>
    getRef(PLAYER_REFS.AUDIO_CONTEXT) as
      | MutableRefObject<AudioContext>
      | undefined

  const bufferSrcArray = () =>
    getRef(PLAYER_REFS.AUDIO_BUFFER_SRC_ARRAY) as
      | MutableRefObject<Array<EventAudioBuffer>>
      | undefined

  const audioBuffer = () =>
    getRef(PLAYER_REFS.AUDIO_BUFFER) as
      | MutableRefObject<AudioBuffer>
      | undefined

  const bufferSrc = () =>
    getRef(PLAYER_REFS.AUDIO_BUFFER_SRC) as
      | MutableRefObject<AudioBufferSourceNode | null>
      | undefined

  const loadBuffers = async () => {
    const ctx = audioCtx()?.current
    if (!ctx || !tracks) return
    // Enable synchronous loading for Android versions below `ANDROID_DEGRADATION_VERSION`.
    // We do this due to old android phones crashing when loading many audio buffers asynchronously.
    const androidVersion = await getAndroidVersion()
    const runSynchronous =
      androidVersion && androidVersion <= ANDROID_DEGRADATION_VERSION
    if (runSynchronous) {
      console.info(
        '📢 Running synchronously due to Android version',
        androidVersion,
      )
    }

    // Load tracks into separate buffers synchronously or asynchronously
    if (!runSynchronous) {
      const buffers = await Promise.all(
        tracks.map(async eventTrack => {
          console.info('Load track URL', eventTrack.url)
          const buffer = await getAudioData(ctx, eventTrack.url)
          console.info(`Track: ${eventTrack.url} loaded and stored`)

          // Get duration of buffer
          const duration = buffer.duration

          return {
            ...eventTrack,
            duration,
            buffer,
          }
        }),
      )
      return buffers
    } else {
      const buffers = []
      for (const eventTrack of tracks) {
        console.info('Load track URL', eventTrack.url)
        const buffer = await getAudioData(ctx, eventTrack.url)
        console.info(`Track: ${eventTrack.url} loaded and stored`)

        // Get duration of buffer
        const duration = buffer.duration

        buffers.push({
          ...eventTrack,
          duration,
          buffer,
        })
      }
      return buffers
    }
  }

  // Returns a buffer and the relative time position of that buffer in the long audio sequence
  const getLongAudioSequenceByTime = (eventId: string, time: number) => {
    // All tracks for long audio matching the id sorted by sequence
    const _tracks = bufferSrcArray()
      ?.current?.filter(t => t.eventId === eventId)
      .sort((a, b) => {
        return a.sequence - b.sequence
      })

    console.info(`Long audio tracks for id: ${eventId}`, tracks)

    if (_tracks) {
      let sequenceId = 0
      let duration = 0
      // Determine the which part of the long audio to use based on time
      for (let i = 0; i < tracks.length; i++) {
        if (time > duration && time < duration + _tracks[i].duration) {
          sequenceId = tracks[i].sequence
          break
        }
        // Increase the duration if this part of the long audio has  already been passed.
        duration += _tracks[i].duration
      }

      // Select the buffer for the matching sequence
      const _buffer = _tracks.find(t => t.sequence === sequenceId)

      return {
        timePosition: duration,
        buffer: _buffer,
      }
    }
  }

  const setupBuffer = async (eventTrack: EventTrack | null, time?: number) => {
    // For initial loading if we do not have a content_id that sets a track we then
    // load the blank track. This is to allow users to start playback before a show is set.
    // The blank track is a silent audio file for safety reasons only, but it will never be played.
    let track = eventTrack
    if (!track) {
      track = BLANK_TRACK
    }
    const ctx = audioCtx()?.current

    if (!ctx) return

    console.info('🚛 🎶  setupBuffer', track, audioBuffer()?.current, time)

    if (!audioBuffer()?.current && time !== undefined) {
      console.info(
        'Initial Buffer Setup',
        track.longAudio,
        track.sequence,
        time,
      )

      // Load all the available buffers
      const loadedTracks = await loadBuffers()

      if (!loadedTracks) {
        throw new Error('No buffers loaded')
      }

      // Store the alternate buffers
      setRefValue(PLAYER_REFS.AUDIO_BUFFER_SRC_ARRAY, loadedTracks)
      console.info('All Track Buffers loaded and stored')

      // Get the matching buffer to starting track
      const buffer = loadedTracks.find(
        t =>
          track.eventId === t.eventId &&
          (track.longAudio ? t.sequence === track.sequence : true), // Sequence match if a long audio track
      )?.buffer

      console.info('Initial Buffer', buffer)
      if (!buffer) {
        throw new Error('No buffer found')
      }

      setRefValue(PLAYER_REFS.AUDIO_BUFFER, buffer)
    }

    // Long audio check and adjust track based on time
    if (track.longAudio && track.sequence && time) {
      // Check for correct buffer based on time for long audio
      const sequenceByTime = getLongAudioSequenceByTime(track.eventId, time)
      if (sequenceByTime) {
        // Check for correct buffer based on time for long audio
        const { buffer: longAudioBuffer, timePosition } = sequenceByTime
        // Update buffer with long audio buffer if different
        if (longAudioBuffer && longAudioBuffer.url !== track.url) {
          setRefValue(PLAYER_REFS.AUDIO_BUFFER, longAudioBuffer.buffer)
          // store the time of the long audio segments that have played
          setLongAudioTime(timePosition)
        }
      }
    }
    setBufferCounter(bufferCounter + 1)
  }

  const setBuffer = async (track: EventTrack, time?: number) => {
    const ctx = audioCtx()?.current
    const currentBuffer = audioBuffer()?.current
    if (!ctx || !currentBuffer || !bufferSrc) return

    // Long audio check and adjust track based on time
    if (track.longAudio && track.sequence && time) {
      // Check for correct buffer based on time for long audio
      const sequenceByTime = getLongAudioSequenceByTime(track.eventId, time)
      if (sequenceByTime) {
        // Check for correct buffer based on time for long audio
        const { buffer: longAudioBuffer, timePosition } = sequenceByTime
        // Update buffer with long audio buffer if different
        if (longAudioBuffer && longAudioBuffer.url !== track.url) {
          setRefValue(PLAYER_REFS.AUDIO_BUFFER, longAudioBuffer.buffer)

          // store the time of the long audio segments that have played
          setLongAudioTime(timePosition)
        }
      }
    }

    setBufferCounter(bufferCounter + 1)
  }

  const replaceLongAudioBuffer = (
    track: EventTrack,
    time: number,
    playState: PlayerState,
  ) => {
    const ctx = audioCtx()?.current
    if (!ctx) return

    console.info('Replace Long Audio Buffer', track, time)

    // Long audio check and adjust track based on time
    if (track.longAudio && track.sequence) {
      // Check for correct buffer based on time for long audio
      const sequenceByTime = getLongAudioSequenceByTime(track.eventId, time)

      if (sequenceByTime) {
        const { buffer: longAudioBuffer, timePosition } = sequenceByTime
        // Update buffer with long audio buffer if different to current buffer/long buffer
        console.log(
          'longAudioBuffer.url',
          longAudioBuffer?.url,
          'track.url',
          track.url,
        )
        if (longAudioBuffer && longAudioBuffer.url !== track.url) {
          console.info('Long Audio Buffer updating')
          // Store the time of where the long audio buffer starts relative to the full long audio.
          setLongAudioTime(timePosition)

          setRefValue(PLAYER_REFS.AUDIO_BUFFER, longAudioBuffer.buffer)
          setBufferCounter(bufferCounter + 1)

          // Return the matching Audio EventTrack for the newly loaded buffer.
          return tracks.find(
            t =>
              t.sequence === longAudioBuffer?.sequence &&
              t.eventId === track.eventId,
          )
        }
      }
    }

    return
  }

  const replaceBuffer = async (eventId: string, playState: PlayerState) => {
    const ctx = audioCtx()?.current
    const currentBufferSrcArray = bufferSrcArray()?.current

    if (!ctx || !currentBufferSrcArray) return

    const buffer = currentBufferSrcArray.find(
      b => b.eventId === eventId,
    )?.buffer

    if (!buffer) return

    // Reset Long audio timer
    setLongAudioTime(0)

    // Replace the buffer
    setRefValue(PLAYER_REFS.AUDIO_BUFFER, buffer)
    setBufferCounter(bufferCounter + 1)
  }

  const currentBufferSrcArray = bufferSrcArray()?.current

  return {
    bufferCounter,
    setupBuffer,
    setBuffer,
    replaceBuffer,
    replaceLongAudioBuffer,
    getLongAudioSequenceByTime,
    longAudioTime,
    allBuffersLoaded: currentBufferSrcArray
      ? currentBufferSrcArray.length > 0
      : false,
  }
}
