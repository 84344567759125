import styled from 'styled-components'

import { Image } from './Image'
import { Label } from './typography'

import { useConfigProperties } from 'hooks/useEsConfig'
import { useLocaleTranslation } from 'hooks/useLocaleTranslation'
import { space } from 'theme/utils'

const SponsorsSection = styled.section`
  width: 100%;

  > * {
    padding-bottom: ${space(5)};
  }
  img {
    max-width: 80%;
    margin: 0 auto;
  }
`

export const Sponsors = () => {
  const { _ } = useLocaleTranslation('Sponsors')
  const { sponsors } = useConfigProperties()
  return sponsors.length > 0 ? (
    <SponsorsSection>
      <Label as="h3" $size="large">
        {_('title')}
      </Label>
      <Image assets={sponsors} />
    </SponsorsSection>
  ) : null
}
