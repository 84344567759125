import type { MutableRefObject } from 'react'

import type { TFunction } from 'i18next'

import { getConfigData } from './config'

import type { PlayerState } from 'types/player'
import type WebsocketService from 'websocket-service'

export const getAudioData = async (aCtx: AudioContext, track: string) => {
  console.log('getAudioData', track)
  return fetch(track, {
    headers: new Headers({ 'content-type': 'audio/m4a' }),
    cache: 'default',
  })
    .then(response => response.arrayBuffer())
    .then(arrayBuffer => aCtx.decodeAudioData(arrayBuffer))
}

export function isAnyOfPlayerStates(
  state: PlayerState,
  ...states: PlayerState[]
): boolean {
  return states.some(s => s === state)
}

export function isNoneOfPlayerStates(
  state: PlayerState,
  ...states: PlayerState[]
): boolean {
  return !isAnyOfPlayerStates(state, ...states)
}

export function renderOnAnyOfStates(
  state: PlayerState,
  ...states: PlayerState[]
) {
  return (elements: JSX.Element) => {
    if (!isAnyOfPlayerStates(state, ...states)) {
      return null
    }

    return elements
  }
}

export function renderOnNoneOfStates(
  state: PlayerState,
  ...states: PlayerState[]
) {
  return (elements: JSX.Element) => {
    if (!isNoneOfPlayerStates(state, ...states)) {
      return null
    }

    return elements
  }
}

/**
 * Get the current delay between the server and the client
 */
export const getCurrentDelay = ({
  audioCtx,
  socket,
  startTime,
  audioDiff,
  streamOffset,
}: {
  audioCtx: AudioContext
  socket: MutableRefObject<WebsocketService>
  startTime: number
  audioDiff: MutableRefObject<number>
  streamOffset: MutableRefObject<number>
}): number => {
  const epoch = socket.current.getEpochTime()
  let diff = (epoch - startTime) / 1000.0
  // Prevent negative diff
  diff = diff > 0 ? diff : 0

  const playbackTime = audioCtx.currentTime - streamOffset.current

  const differenceWithLocalTimePlayedRemoved =
    diff - (playbackTime + audioDiff.current)
  return Math.abs(differenceWithLocalTimePlayedRemoved)
}

export function checkIsHighDelayAllowancePercent(
  audioCtx: AudioContext,
  socket: MutableRefObject<WebsocketService>,
  startTime: number,
  audioDiff: MutableRefObject<number>,
  streamOffset: MutableRefObject<number>,
  bufferDuration: number | undefined,
) {
  const epoch = socket.current.getEpochTime()
  const audioConfig = getConfigData('audioConfig')
  let diff = (epoch - startTime) / 1000.0
  // Prevent negative diff
  diff = diff > 0 ? diff : 0

  const playbackTime = audioCtx.currentTime - streamOffset.current

  const differenceWithLocalTimePlayedRemoved =
    diff - (playbackTime + audioDiff.current)
  const delay = Math.abs(differenceWithLocalTimePlayedRemoved)

  console.group('⏰ Sync Playback info')
  console.info(`Delay: ${delay}`)
  console.info(`Delay Allowance Percent: ${audioConfig.delayAllowancePercent}`)
  console.info(
    `Info: playbackTime ${playbackTime} | diff: ${diff} | streamOffset: ${streamOffset.current} | startTime: ${startTime} | bufferDuration ${bufferDuration}`,
  )
  console.info(
    `Stored Diff: ${audioDiff.current} | Latest Diff without playback ${differenceWithLocalTimePlayedRemoved} | Delay: ${delay}`,
  )
  console.groupEnd()

  // Reload if delay exceeds allowance
  return delay >= audioConfig.delayAllowancePercent
}

export const setupIOSMediaSession = (
  t: TFunction<'translation', undefined>,
) => {
  // Audio Player data for lock screen audio players and iOS Dynamic Island
  if ('mediaSession' in navigator) {
    navigator.mediaSession.metadata = new MediaMetadata({
      title: t('Audio.title'),
      artist: t('Audio.artist'),
      artwork: [
        {
          src: '/favicons/audio-artwork-512x512.png',
          sizes: '512x512',
          type: 'image/png',
        },
      ],
    })
  }
}
